<template>
   <v-dialog v-model="dialog" max-width="600px">
      <Notification type="error" message="Error" ref="error_noti" />
      <Dialog
         :maxWidth="450"
         message="La comisión es un importe que se cobra cuando el total del cargo o abono a una cuenta se encuentra en rango desde: igual o mayor al mínimo, pero menor y no exactamente igual al máximo"
         ref="comision_help"
      />
      <v-card :loading="isloading">
         <v-card-title>{{ title }}</v-card-title>
         <v-card-text>
            <form>
               <div class="d-flex align-center">
                  <v-text-field
                     v-model="nombre"
                     label="Nombre"
                     :error-messages="nombreErrors"
                     required
                     @input="$v.nombre.$touch()"
                     @blur="$v.nombre.$touch()"
                  ></v-text-field>
                  <v-btn icon @click="$refs.comision_help.Show()"><v-icon>mdi-help-circle</v-icon></v-btn>
               </div>
               <v-row>
                  <v-col>
                     <v-select
                        v-model="tipo"
                        :items="items"
                        label="Tipo"
                        item-text="text"
                        item-value="value"
                        :error-messages="tipoErrors"
                        required
                        @input="$v.tipo.$touch()"
                        @blur="$v.tipo.$touch()"
                     ></v-select>
                  </v-col>
                  <v-col class="d-flex justify-center" cols="3">
                     <v-switch
                        v-model="tipo_cobro"
                        true-value="P"
                        false-value="I"
                        :append-icon="tipo_cobro == 'P' ? 'mdi-percent' : '   '"
                        :prepend-icon="tipo_cobro == 'I' ? 'mdi-currency-usd' : '   '"
                     ></v-switch>
                  </v-col>
               </v-row>
               <v-row>
                  <v-col>
                     <v-text-field
                        v-model="min"
                        label="Mínimo"
                        type="number"
                        prefix="$"
                        :error-messages="minErrors"
                        required
                        @input="$v.min.$touch()"
                        @blur="$v.min.$touch()"
                     ></v-text-field>
                  </v-col>
                  <v-col>
                     <v-text-field
                        v-model="max"
                        label="Máximo"
                        type="number"
                        prefix="$"
                        :error-messages="maxErrors"
                        required
                        @input="$v.max.$touch()"
                        @blur="$v.max.$touch()"
                     ></v-text-field>
                  </v-col>
               </v-row>
               <v-text-field
                  v-model="importe"
                  :label="tipo_cobro == 'P' ? 'Porcentaje' : 'Comisión' "
                  type="number"
                  :prefix="tipo_cobro == 'P' ? '%' : '$'"
                  :error-messages="importeErrors"
                  required
                  @input="$v.importe.$touch()"
                  @blur="$v.importe.$touch()"
               ></v-text-field>
            </form>
         </v-card-text>
         <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="close">Cancelar</v-btn>
            <v-btn :loading="isloading" color="primary" @click="save">Guardar</v-btn>
         </v-card-actions>
      </v-card>
   </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength, minLength } from "vuelidate/lib/validators";

export default {
   mixins: [validationMixin],
   validations: {
      nombre: {
         required,
         maxLength: maxLength(30),
         minLength: minLength(5),
      },
      tipo: {
         required,
      },
      min: {
         required,
         maxLength: maxLength(30),
         positivo: function () {
            return this.min >= 0;
         },
      },
      max: {
         required,
         maxLength: maxLength(30),
         positivo: function () {
            return this.max >= 0;
         },
         bigger: function () {
            return +this.max > +this.min;
         },
      },
      importe: {
         required,
         maxLength: maxLength(30),
         positivo: function () {
            return this.importe >= 0;
         },
      },
   },
   data: function () {
      return {
         comision_id: "",
         nombre: "",
         tipo: null,
         tipo_cobro: "I",
         min: 0,
         max: 0,
         importe: 0,
         dialog: false,
         isloading: false,
         items: [
            {
               text: "Abono",
               value: "A",
            },
            {
               text: "Cargo",
               value: "C",
            },
         ],
      };
   },
   watch: {
      dialog: function (val) {
         if (!val) {
            this.close();
            this.$emit("close");
         }
      },
   },
   methods: {
      save: async function () {
         try {
            this.$v.$touch();
            if (!this.$v.$invalid) {
               this.isloading = true;
               let res = null;
               if (!this.isEdit) {
                  res = await this.$store.dispatch("insertComision", {
                     nombre: this.nombre,
                     tipo: this.tipo,
                     min: this.min,
                     max: this.max,
                     importe: this.importe,
                     porcentaje: this.importe,
                     tipo_cobro: this.tipo_cobro,
                  });
               } else {
                  res = await this.$store.dispatch("updateComision", {
                     comision_id: this.comision_id,
                     nombre: this.nombre,
                     tipo: this.tipo,
                     min: this.min,
                     max: this.max,
                     importe: this.importe,
                     porcentaje: this.importe,
                     tipo_cobro: this.tipo_cobro,
                  });
               }
               if (res == true) {
                  this.$emit("save");
                  this.close();
               } else {
                  throw res;
               }
            } else {
               throw "error";
            }
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         } finally {
            this.isloading = false;
         }
      },
      close: function () {
         this.dialog = false;
         this.$v.$reset();
      },
      show: function (i) {
         this.dialog = true;
         this.comision_id = i.comision_id;
         this.nombre = i.nombre;
         this.min = i.min;
         this.max = i.max;
         this.tipo_cobro = i.tipo_cobro;
         this.importe = this.tipo_cobro == 'P' ? i.porcentaje : i.importe;
         this.tipo = i.tipo;
      },
   },
   computed: {
      isEdit() {
         if (this.comision_id) {
            return true;
         } else {
            return false;
         }
      },
      title() {
         if (this.comision_id) {
            return "Editar Comisión";
         } else {
            return "Crear Comisión";
         }
      },
      nombreErrors() {
         const errors = [];
         if (!this.$v.nombre.$dirty) return errors;
         !this.$v.nombre.required && errors.push("El nombre es requerido");
         !this.$v.nombre.maxLength && errors.push("Debe contener maximo 30 caracteres");
         !this.$v.nombre.minLength && errors.push("Debe contener minimo 5 caracteres");
         return errors;
      },
      tipoErrors() {
         const errors = [];
         if (!this.$v.tipo.$dirty) return errors;
         !this.$v.tipo.required && errors.push("El tipo es requerido");
         return errors;
      },
      minErrors() {
         const errors = [];
         if (!this.$v.min.$dirty) return errors;
         !this.$v.min.required && errors.push("El mínimo es requerido");
         !this.$v.min.maxLength && errors.push("Debe contener maximo 30 caracteres");
         !this.$v.min.positivo && errors.push("Debe ser un numero positivo");
         return errors;
      },
      maxErrors() {
         const errors = [];
         if (!this.$v.max.$dirty) return errors;
         !this.$v.max.required && errors.push("El máximo es requerido");
         !this.$v.max.maxLength && errors.push("Debe contener maximo 30 caracteres");
         !this.$v.max.positivo && errors.push("Debe ser un numero positivo");
         !this.$v.max.bigger && errors.push("Debe ser un numero mayor al minimo");
         return errors;
      },
      importeErrors() {
         const errors = [];
         if (!this.$v.importe.$dirty) return errors;
         !this.$v.importe.required && errors.push("El importe es requerido");
         !this.$v.importe.maxLength && errors.push("Debe contener maximo 30 caracteres");
         !this.$v.importe.positivo && errors.push("Debe ser un numero positivo");
         return errors;
      },
   },
};
</script>
