<template>
   <div class="ma-6">
      <Comision @save="$refs.ok_noti.Show()" ref="comision" />
      <Dialog message="¿Desea eliminar la comisión?" @result_ok="confirmDeleteItem()" ref="deleteItem" />
      <Notification type="error" message="Error" ref="error_noti" />
      <Notification type="success" message="Información actualizada" ref="ok_noti" />
      <v-data-table :loading="isloading" :headers="headers" :items="$store.state.comisiones" :items-per-page="15" class="elevation-1">
         <template v-slot:top>
            <v-toolbar flat color="secondary">
               <v-btn @click="newItem()"><v-icon left>mdi-plus-circle</v-icon>Nueva comisión</v-btn>
               <v-btn @click="initialize" icon small class="ml-2"><v-icon small>mdi-autorenew</v-icon></v-btn>
               <v-spacer></v-spacer>
            </v-toolbar>
         </template>
         <template v-slot:[`item.tipo`]="{ item }">
            {{ item.tipo == "C" ? "Cargo" : "Abono" }}
         </template>
         <template v-slot:[`item.importe`]="{ item }">
            <div v-if="item.tipo_cobro == 'P'">{{item.porcentaje}}%</div>
            <div v-if="item.tipo_cobro == 'I'">${{item.importe}}</div>
         </template>
         <template v-slot:[`item.tipo_cobro`]="{ item }">
            <v-icon small color="warning" v-if="item.tipo_cobro == 'P'">mdi-percent</v-icon>
            <v-icon small color="success" v-if="item.tipo_cobro == 'I'">mdi-currency-usd</v-icon>
         </template>
         <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
            <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
         </template>
      </v-data-table>
   </div>
</template>

<script>
import Comision from "./Comisiones/Comision.vue";

export default {
   components: {
      Comision,
   },
   data() {
      return {
         headers: [
            { text: "ID", value: "comision_id" },
            { text: "Nombre", value: "nombre" },
            { text: "Tipo", value: "tipo" },
            { text: "Cobro", value: "tipo_cobro" },
            { text: "Importe", value: "importe" },
            { text: "Min", value: "min" },
            { text: "Max", value: "max" },
            { text: "Acciones", value: "actions" },
         ],
         itemToDelete: null,
         isloading: true,
      };
   },
   methods: {
      async initialize() {
         try {
            this.isloading = true;
            await this.$store.dispatch("getComisiones");
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         } finally {
            this.isloading = false;
         }
      },
      async newItem() {
         if (this.$permiso("insertar_comisiones"))
            this.$refs.comision.show({
               comision_id: null,
               nombre: "",
               min: 0,
               max: 0,
               importe: 0,
               porcentaje: 0,
               tipo_cobro: 'I',
               tipo: null,
            });
      },
      async editItem(item) {
         if (this.$permiso("actualizar_comisiones"))
            this.$refs.comision.show({
               comision_id: item.comision_id,
               nombre: item.nombre,
               min: item.min,
               max: item.max,
               importe: item.importe,
               porcentaje: item.porcentaje,
               tipo_cobro: item.tipo_cobro,
               tipo: item.tipo,
            });
      },
      async deleteItem(item) {
         if (this.$permiso("eliminar_comisiones")) {
            this.itemToDelete = item;
            this.$refs.deleteItem.Show();
         }
      },
      async confirmDeleteItem() {
         try {
            this.isloading = true;
            let res = null;
            res = await this.$store.dispatch("deleteComision", {
               comision_id: this.itemToDelete.comision_id,
            });
            if (res == true) {
               this.$refs.ok_noti.Show();
            } else {
               throw res;
            }
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         } finally {
            this.isloading = false;
         }
      },
   },
   created() {
      this.initialize();
   },
};
</script>

<style></style>
