var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ma-6"},[_c('Comision',{ref:"comision",on:{"save":function($event){return _vm.$refs.ok_noti.Show()}}}),_c('Dialog',{ref:"deleteItem",attrs:{"message":"¿Desea eliminar la comisión?"},on:{"result_ok":function($event){return _vm.confirmDeleteItem()}}}),_c('Notification',{ref:"error_noti",attrs:{"type":"error","message":"Error"}}),_c('Notification',{ref:"ok_noti",attrs:{"type":"success","message":"Información actualizada"}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.isloading,"headers":_vm.headers,"items":_vm.$store.state.comisiones,"items-per-page":15},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"secondary"}},[_c('v-btn',{on:{"click":function($event){return _vm.newItem()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus-circle")]),_vm._v("Nueva comisión")],1),_c('v-btn',{staticClass:"ml-2",attrs:{"icon":"","small":""},on:{"click":_vm.initialize}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-autorenew")])],1),_c('v-spacer')],1)]},proxy:true},{key:"item.tipo",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.tipo == "C" ? "Cargo" : "Abono")+" ")]}},{key:"item.importe",fn:function(ref){
var item = ref.item;
return [(item.tipo_cobro == 'P')?_c('div',[_vm._v(_vm._s(item.porcentaje)+"%")]):_vm._e(),(item.tipo_cobro == 'I')?_c('div',[_vm._v("$"+_vm._s(item.importe))]):_vm._e()]}},{key:"item.tipo_cobro",fn:function(ref){
var item = ref.item;
return [(item.tipo_cobro == 'P')?_c('v-icon',{attrs:{"small":"","color":"warning"}},[_vm._v("mdi-percent")]):_vm._e(),(item.tipo_cobro == 'I')?_c('v-icon',{attrs:{"small":"","color":"success"}},[_vm._v("mdi-currency-usd")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }