<template>
  <Comisiones />
</template>

<script>
import Comisiones from "../components/Comisiones/Comisiones.vue"
export default {
  components:{
    Comisiones
  }
}
</script>

<style>

</style>